import ReactGA from 'react-ga';
import moment from 'moment';
import axios from 'axios';
import i18n from 'i18next';
import firebase from '../../../../../firebase';
import { receiveLogout, logout } from '../logout/actions';
import { loadAccount } from '../../../actions';
import { getSubscriptions } from '../../subscriptions/actions';
import { findFirst, remove500OfferAccountIds } from '../../../../../utils';
import { setAutoPopup } from '../../../../../app/modules/notices/actions';
import { DIY, DELUX, SOMETHING } from '../../../../../plans';
import {
  setOfferEligibility,
  addWelcomeNotice,
  fetchAppsumoUserDetail,
} from '../../../modules/register/actions';
import { getAppSubscription } from '../../../../../utils/appDetails';

const getIpData = uid => dispatch => {
  return firebase
    .database()
    .ref(`users/${uid}/ip_data/`)
    .once('value', async snapshot => {
      if (!snapshot.exists()) {
        axios
          .get('https://ipapi.co/json/')
          .then(res => {
            firebase
              .database()
              .ref(`users/${uid}/ip_data/`)
              .update({
                ip_address: res.data.ip,
                city: res.data.city,
                country: res.data.country_name,
              })
              .then();
          })
          .catch(err => console.error(err));
      }
    })
    .then(ip => {
      const ipData = ip.val().ip_address;

      return firebase
        .database()
        .ref('blacklisted_ip')
        .once('value')
        .then(blacklistIP => {
          const list = blacklistIP.val();
          if (list.includes(ipData)) {
            dispatch(logout());
          } else {
            dispatch({ type: 'USER_NOT_BLOCKED' });
          }
        })
        .catch(err => console.error(err));
    })
    .catch(err => console.error(err));
};

const requestLogin = creds => ({
  type: 'LOGIN_REQUEST',
  creds,
});

const loginSuccess = () => dispatch => {
  const user = firebase.auth().currentUser;
  dispatch(getIpData(user.uid));
  dispatch({
    type: 'LOGIN_SUCCESS',
    user,
  });
};
export const getPlatformTranslation = accountId => (dispatch, getState) => {
  const { organisation } = getState().account;
  const parentAccount = organisation.parentAccount || accountId;
  const ref = firebase.database().ref(`platform_translations/${parentAccount}`);

  ref.once('value', snapshot => {
    const data = snapshot.val();
    if (snapshot.exists()) {
      dispatch({
        type: 'PLATFORM_LANG',
        value: data,
      });
    }
  });
};

const loginError = message => ({
  type: 'LOGIN_FAILURE',
  message,
});

/************************************
 ************* PROFILE **************
 ************************************/

const loadingProfile = () => ({
  type: 'LOADING_PROFILE',
});

const hydrateProfile = data => ({
  type: 'HYDRATE_PROFILE',
  data,
});

/**
 * Loads user 'profile' from /users/:uid
 * @returns {Promise} - Loads user profile from uid
 */
export const loadProfile = () => dispatch => {
  dispatch(loadingProfile());

  return new Promise((resolve, reject) => {
    const { uid } = firebase.auth().currentUser;
    const ref = firebase.database().ref(`users/${uid}`);

    ref.once('value', snapshot => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        dispatch(hydrateProfile(data));
        return resolve(data);
      }

      return reject(new Error(i18n.t('Profile does not exist')));
    });
  });
};

/**
 * Series of promises to receive profile and dispatch account data and login user
 */
export const receiveLogin = () => async (dispatch, getState) => {
  try {
    dispatch({ type: 'RECEIVE_LOGIN' });
    const profile = await dispatch(loadProfile());
    await dispatch(loadAccount(profile.account));
    const appsumoUserId = getState().account.organisation.appsumo_id;
    await dispatch(fetchAppsumoUserDetail(appsumoUserId));
    await dispatch(getSubscriptions());

    return profile;
  } catch (err) {
    return dispatch(loginError(err.message));
  }
};

const prepareFreshmarketerPayload = allState => {
  const profile = allState.account.profile;
  const appContext = allState.app.appContext;
  const lastLogin = moment(new Date()).format('DD/MM/YYYY hh:mm:ss');
  const created_at = moment(profile.created_at).format('DD/MM/YYYY hh:mm:ss');
  const account = profile.account;
  const admin_appname = profile.admin_appname ? profile.admin_appname : null;
  const isAppsumoAccount = !!allState.account.organisation.appsumo_id;
  const isAppAdmin =
    (allState.account.profile.admin_appnames &&
      allState.account.profile.admin_appnames.length > 0) ||
    false;
  const body = {
    account,
    lastLogin,
    created_at,
    appname: appContext.appname,
    currentPlan: appContext.subscription,
    admin_appname,
    isAppsumoAccount,
    isAppAdmin,
  };
  const location = [];

  if (profile.city && profile.city.value) {
    location.push(profile.city.value);
  }
  if (profile.country && profile.country.value) {
    location.push(profile.country.value);
  }

  if (location.length > 0) {
    body.location = location.join(', ');
  }
  if (profile.name && profile.name.value) {
    const pieces = profile.name.value.split(' ');
    if (pieces.length > 1) {
      body.fname = pieces[0];
      body.lname = pieces[1];
    } else {
      body.fname = profile.name.value;
      body.lname = profile.name.value;
    }
  }
  const listName = getListByPlanName(
    appContext.subscription,
    allState.account.organisation,
  );
  body.lists = listName;
  return body;
};

/**
 * Update user Login count
 */
export const updateLoginDetails = () => async dispatch => {
  try {
    dispatch({ type: 'LOGIN_COUNT' });

    await firebase
      .database()
      .ref(`users/${firebase.auth().currentUser.uid}/`)
      .update({
        last_logged_in: Date.now(),
        email: firebase.auth().currentUser.email,
        uid: firebase.auth().currentUser.uid,
      });

    return new Promise((resolve, reject) => {
      const { uid } = firebase.auth().currentUser;
      const ref = firebase.database().ref(`users/${uid}`);
      ref.once('value', snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const newCount = data.login_count ? data.login_count + 1 : 1;
          const isAppAdmin =
            (data.admin_appname && data.admin_appname.length > 0) || false;

          ref.child('login_count').set(newCount);
          ref.child('sign_up_dates').push(Date.now());
          return resolve(data);
        }

        return reject(new Error(i18n.t('uid does not exist')));
      });
    });
  } catch (err) {
    return dispatch(loginError(err.message));
  }
};

export const sendEmailVerification = email => async dispatch => {
  try {
    if (!email) {
      return Promise.reject();
    }

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    await fetch(`${process.env.REACT_APP_CREATE_API}/send-email-verification`, {
      headers,
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({
        email,
      }),
    });
  } catch (err) {
    return dispatch(loginError(err.message));
  }
};

/**
 * Loads user profile when logged in.
 * Redirects and flushes data when logged out.
 */
export const startListeningToAuthChange = callback => (dispatch, getState) => {
  dispatch({ type: 'LISTEN_TO_AUTH_CHANGE' });

  firebase.auth().onAuthStateChanged(user => {
    // don't receiveLogin if registration is pending
    if (getState().account.register.pending) {
      return null;
    }

    if (user) {
      if (process.env.NODE_ENV === 'production' && user.uid) {
        ReactGA.set({
          userId: user.uid,
        });
      }

      return dispatch(receiveLogin())
        .then(() => {
          callback(user);
          // delay to prevent flash of login screen
          setTimeout(() => dispatch(loginSuccess()), 600);
        })
        .catch(err => {
          console.error(err);
          dispatch(loginError(err.message));
          // callback on error with undefined param will redirect to login
          callback();
        });
    }

    callback();
    dispatch(receiveLogout());

    if (
      window.self !== window.top &&
      getState().account.organisation.integration &&
      getState().account.organisation.integration.type === 'Wix'
    ) {
      // Redirect to Wix if user doesn't come from Wix (e.g. weebly or core paltform) but is inside Wix dashboard
      const myAccountUrl = 'https://www.wix.com/my-account/sites/';
      window.top.location.replace(myAccountUrl);
    }
  });
};

/**
 * LOGIN
 * Calls firebase login.
 * Next step is handled by startListeningToAuthChange
 */

export const loginUser = (creds, offer) => async (dispatch, getState) => {
  dispatch(requestLogin(creds));
  const accIds = await remove500OfferAccountIds();
  return firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password)
    .then(async () => {
      dispatch(updateLoginDetails());
      await dispatch(receiveLogin());
      // Freshmarketer api
      const payload = prepareFreshmarketerPayload(getState());
      if (payload && !payload.isAppAdmin) {
        dispatch(updateContact({ ...payload }));
      }
      if (window.FM) {
        window.FM.associateVisitor(creds.email);
      }
      // dispatch(pushAccountCreatedDate(account.profile));
      const account = getState().account;

      checkAndSetOfferEligibility(account, offer).then(async () => {
        const appSubscription = await getAppSubscription(
          account.auth.profile.lastVisited,
        );
        const accountId = account.auth.profile.account;
        const allNotices = account.organisation.notices;
        const offerEligibility = account.organisation.offerEligibility || {};

        if (
          allNotices &&
          offerEligibility.lifetime &&
          !(
            appSubscription &&
            appSubscription.plan === 'deluxe' &&
            appSubscription.period === 'lifetime'
          ) &&
          !(accIds.indexOf(account.profile.account) > -1)
        ) {
          const autoPopup = findFirst(allNotices, o => k =>
            o[k].autoPopup === false && o[k].type === 'Offer',
          );
          if (autoPopup) {
            dispatch(setAutoPopup(account.profile.account, autoPopup));
          }
        }
      });
    })
    .catch(error => dispatch(loginError(error.message)));
};

export const pushAccountCreatedDate = profile => dispatch => {
  const isAppAdmin =
    (profile.admin_appname && profile.admin_appname.length > 0) || false;
  if (!isAppAdmin) {
  }
};
export const saveOfferFromQueryString = offer => ({
  type: 'SAVING_OFFER_FROM_QUERYSTRING',
  offer,
});

const checkAndSetOfferEligibility = (account, offer) =>
  new Promise((resolve, reject) => {
    if (offer && offer.coupon) {
      const accountId = account.profile.account;
      setOfferEligibility(accountId, offer)
        .then(() => {
          const isAppAdmin =
            (account.auth.profile.admin_appname &&
              account.auth.profile.admin_appname.length > 0) ||
            false;
          if (!account.organisation.appsumo_id && !isAppAdmin) {
            addWelcomeNotice(accountId, offer);
          }
          return accountId;
        })
        .then(() => resolve());
    } else {
      return resolve();
    }
  });

export const setOfferEligibilityForReturningUser = (
  account,
  offer,
) => dispatch => checkAndSetOfferEligibility(account, offer);

export const updateContact = data => dispatch => {
  const headers = new Headers();
  if (data && data.lists === 'adillo' && !data.isPayment) {
    data.lists = 'adilloSignup';
  }
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_FRESHMARKETER_API}/update`, {
        headers,
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({ ...data }),
      });
    })
    .then(() => dispatch({ type: 'UPDATED_CONTACT' }));
};

export const getListByPlanName = (plan, org) => {
  switch (plan) {
    case DELUX:
      return 'everything-plan';
    case DIY:
      if (org && org.legacy_flag === 20) {
        return 'more-of-everything-plan';
      }
      return 'anything-plan';
    case SOMETHING:
      return 'something-plan';
    default:
      return 'free-plan';
  }
};
